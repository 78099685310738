<template>
  <div>
    <v-app-bar color="#0c111b" elevation="0" prominent dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <div class="logo">
        <img
          class="img-logo"
          src="../assets/logo_goat-removebg-preview.png"
          alt="Storm Proof"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      class="drawer #0c111b accent-4"
      absolute
      temporary
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="azul-goat darken-2 white--text"
        >
          <v-list-item class="mb-2" to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-2" to="/services">
            <v-list-item-icon>
              <v-icon>mdi mdi-account-hard-hat</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-2" to="/gallery">
            <v-list-item-icon>
              <v-icon>mdi mdi-image</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Gallery</v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-2" to="/contact">
            <v-list-item-icon>
              <v-icon>mdi mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="logo-bottom pa-2">
        <img
          class="img-logo2"
          src="../assets/logo_goat-removebg-preview.png"
          alt="Storm Proof"
        />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "MenuPhoneBar",

  data() {
    return {
      page: "home",
      drawer: false,
      group: null,
    };
  },
};
</script>


<style>
.logo {
  margin-left: -48px;
}

.logo-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 70vh;
}

.logo-bottom .v-list {
  height: 30vh !important;
}

.logo {
  display: flex;
  justify-content: center;
  width: 100%;
}

.img-logo {
  width: 100px;
}

.img-logo2 {
  width: 150px;
  height: 150px;
}

.azul-goat {
  background-color: #0c111b;
}
</style>