<template>
  <div class="global">
    <v-app-bar
      :class="{ 'home-menu': isHome, 'normal-menu': !isHome }"
      class="menu wrap-nav-items"
      elevation="0"
      prominent
      dark
      color="transparent"
    >

      <div class="menu-group">
        <div class="top-menu">
          <div class="contact-tags">
            <a href="mailto:sales@goatrenovationsllc.com">
              <v-chip
                style="cursor: pointer"
                class="ma-2"
                color="rgb(18,17,27,0.30)"
                label
                text-color="#cd8502"
              >
                <v-icon left> mdi-email-outline </v-icon>
                <span class="font-weight-bold">
                  sales@goatrenovationsllc.com
                </span>
              </v-chip>
            </a>
            <a href="tel:+12064540769">
              <v-chip
                style="cursor: pointer"
                class="ma-2"
                color="rgb(18,17,27,0.30)"
                label
                text-color="#cd8502"
              >
                <v-icon left> mdi-phone </v-icon>
                <span class="font-weight-bold"> +1 206-454-0769 </span>
              </v-chip>
            </a>
          </div>

          <div class="social-buttons">
            <v-btn
              href="https://www.facebook.com/goatrenovationsllc"
              target="_blank"
              icon
            >
              <v-icon large color="#cd8502"> mdi-facebook </v-icon>
            </v-btn>
            <v-btn
              href="https://www.instagram.com/goatrenovationswa"
              target="_blank"
              icon
            >
              <v-icon large color="#cd8502"> mdi-instagram </v-icon>
            </v-btn>
          </div>
        </div>

        <div class="root-buttos">
          <v-btn plain to="/" large> Home </v-btn>
          <v-btn plain to="/services" large> SERVICES </v-btn>
          <v-btn plain to="/gallery" large> GALLERY </v-btn>
          <v-btn plain to="/contact" large> CONTACT </v-btn>
        </div>
      </div>
    </v-app-bar>
    <div class="slogan">
      <div class="logo pl-6">
        <img
          :class="{ 'home-logo': isHome }"
          class="img-logo"
          src="../assets/logo_goat-removebg-preview.png"
          alt="goat-renovations"
        />
      </div>
      <span :class="{ 'not-home-slogan': !isHome }" class="pt-1"
        >Let us turn reality</span
      >
      <span :class="{ 'not-home-slogan': !isHome }" class="pt-2"
        >Your dream Home</span
      >
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "MenuBar",

  data() {
    return {
      page: "home",
      drawer: false,
      group: null,
      isHome: false,
      route: "home",
    };
  },

  watch: {
    "$route.path": function (newPath) {
      // Cuando cambia la ruta, actualizar el valor isSpecialRoute
      this.isHome = newPath === "/" || newPath === "home";
      console.log(newPath);
      this.route = newPath;
    },
  },

  created() {
    // Cuando cambia la ruta, actualizar el valor isSpecialRoute
    this.isHome = this.$route.path === "/" || this.$route.path === "home";
    console.log(this.$route.path);
    this.route = this.$route.path;
  },
};
</script>
  
<style lang="scss" scoped>
@import "@/responsive";
.v-toolbar__content {
  width: 100% !important;
}

.global {
  display: flex;
  flex-direction: column;
  background-image: url("/src/assets/banner.png");
  background-size: cover; /* Para cubrir todo el fondo */
  background-position: center center;
}

.slogan {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: 5rem;
  margin-top: 1rem;
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.slogan .pt-1 {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: -1.5rem;
  color: white;
  text-shadow: 1px 1px 20px #00000099;
  transition: opacity 0.6s ease-in-out;
}
.slogan .pt-2 {
  font-size: 4rem;
  color: #cd8502;
  font-weight: bold;
  text-shadow: 1px 1px 20px #00000099;
}

.not-home-slogan {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.menu {
  display: flex;
  justify-content: right;
  padding: 0;
  min-height: 140px;
  width: 100% !important;
  transition: ease-in-out 0.5s;
}

.home-menu {
  height: 50vh !important;
}
.normal-menu {
  height: 15vh !important;
}

.logo {
  flex: 1;
  height: 140px;
  display: flex;
  justify-content: center;
}

.menu-group {
  flex: 3;
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  height: 100%;
}

.top-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
}

.root-buttos {
  height: 100%;
  flex: 4;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;
  width: 100%;
  background-color: #0c111b;
}

.contact-tags {
  display: flex;
  flex: 6;
  justify-content: right;
  font-size: 30px;
}

.v-btn--active {
  color: #cd8502 !important;
}

.v-btn--active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #cd8502; /* Color de la línea debajo */
  transform: translateX(-50%);
}

.v-btn:before {
  background-color: transparent !important;
  opacity: 0.9 !important;
}

.social-buttons {
  width: 300px;
  flex: 1;
  display: flex;
  justify-content: right;
}

.social-buttons i:hover {
  color: #0c111b !important;
}

.social-buttons i {
  margin-top: 6px;
  margin-left: 10px;
  cursor: pointer;
}

.social-buttons span {
  font-size: 16px;
}

.drawer {
  height: 100%;
}

.img-logo {
  margin-top: 1rem;
  width: 100px;
  height: 100px;
  transition: ease-in-out 0.5s;
}

.home-logo {
  margin-top: 1rem;
  width: 200px !important;
  height: 200px !important;
}
</style>