<template>
  <section class="home">
    <div class="">
      <div class="about">
        <div class="about-message">
          <p style="font-weight: 200">─ Who we are?</p>
          <h1 style="font-size: 1.8rem; color: #0c111b">
            Goat Renovations & General Contractor LLC,
          </h1>
          is a premier construction firm driven by a passion for excellence and
          a commitment to delivering exceptional results. With a dedicated team
          of skilled professionals, cutting-edge technology, and a
          customer-centric approach, we transform visions into reality, creating
          enduring structures that stand as a testament to our unwavering
          commitment to quality.
        </div>
        <div class="about-image">
          <v-parallax
            style="display: flex; justify-content: center; align-items: center"
            class="image"
            src="../assets/Parallax.png"
          ></v-parallax>
        </div>
      </div>
      <div class="phrase-image">
        <v-icon class="quote" left> mdi-format-quote-open </v-icon>
        <p>Constructing Greatness, G.O.A.T Style – Building the Future, One Project at a Time.</p>
      </div>
      <div class="services-image">
        <h2>Product & Services.</h2>
      </div>
      <div class="services-container">
        <div class="service-image">
          <v-img src="../assets/service1.jpeg"></v-img>
        </div>
        <div class="service-image">
          <v-img src="../assets/service2.jpeg"></v-img>
        </div>
        <div class="service-image">
          <v-img src="../assets/service3.jpeg">
            <div class="service-more">
              <v-btn plain to="/services" style="color: #cd8502" x-large>
                See More.
                <v-icon large color="#cd8502">
                  mdi mdi-arrow-right
                </v-icon></v-btn
              >
            </div>
          </v-img>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "@/responsive";
.home {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  @media (max-width: 1200px) {
    padding: 0 !important;
  }

  @include responsive(mobile) {
    padding: 0 !important;
  }
}

.home-item {
  margin-top: 2rem;
}

.about {
  display: flex;
  gap: 2;
  padding: 2rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    padding: 0rem;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    padding: 0rem;
  }
}

.about-message {
  flex: 1;
  width: 100%;
  height: 100%;
  font-size: 1.2rem;

  @media (max-width: 1200px) {
    margin-top: 3rem;
    width: 100vw;
    padding: 3rem;
  }

  @include responsive(mobile) {
    margin-top: 3rem;
    width: 100vw;
    padding: 3rem;
  }
}

.about-message p {
  font-size: 3rem;
  @include responsive(mobile) {
   font-size: 2.3rem;
  }
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  padding-left: 3rem;

  @media (max-width: 1200px) {
    margin-top: 3rem;
    width: 100vw;
    padding: 2rem;
  }

  @include responsive(mobile) {
    margin-top: 3rem;
    width: 100vw;
    padding: 2rem;
    width: 100%;
  }
}

.image {
  width: 100%;

  @include responsive(mobile) {
    height: 70vh;
    object-fit: contain;
  }
}

.phrase-image {
  margin-top: 4rem;
  width: 100%;
  height: 140px;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../assets/phraseImage.png");
  object-fit: fill;
  background-repeat: repeat-x;
  display: flex;
  align-items: center;
  justify-content: center;
}

.phrase-image p {
  font-size: 2rem;
  opacity: 2;
  margin-left: -2rem;
  font-weight: 400;
  font-style: italic;
  
  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }
  
  @include responsive(mobile) {
    margin-left: 0rem;
    padding: 1.5rem;
    font-size: 1.2rem;
  }
}

.quote {
  font-size: 6rem;
  position: relative;
  left: -35px;
  top: -30px;
  color: #cd8502;

  @media (max-width: 1200px) {
    left: -20px;
    top: -30px;
    font-size: 3rem;
  }

  @include responsive(mobile) {
    font-size: 2rem;
    left: 25px;
    top: -40px;
  }
}

.services-image {
  margin-top: 3rem;
  width: 100%;
  height: 160px;
  background-image: linear-gradient(
      rgba(12, 17, 27, 0.5),
      rgba(12, 17, 27, 0.7)
    ),
    url("/src/assets/patrondMadera.jpeg");
  background-repeat: repeat-x;
  display: flex;
  align-items: top;
  justify-content: space-evenly;
}

.services-image h2 {
  font-size: 2rem;
  margin-top: 0.5rem;
  color: white;
}
.services-container {
  width: 100%;
  min-height: 160px;
  height: 100%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include responsive(mobile) {
    margin-top: -3rem;
    gap: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.service-image {
  margin-top: -5rem;
  width: 20rem;
  object-fit: cover;
  @media (max-width: 1200px) {
    width: 13rem;
  }
  
  @include responsive(mobile) {
    margin-top: -2rem;
    width: 80%;
  }
}

.service-more {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>