<template>
  <v-app id="app">
    <MenuPhoneBar class="phoneMenu" />
    <MenuBar class="menu" />

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <footerBar class="footer" />
    <CopyFooter class="footer" />
  </v-app>
</template>

<script>
import FooterBar from "./components/FooterBar.vue";
import MenuBar from "./components/MenuBar.vue";
import MenuPhoneBar from "@/components/MenuPhoneBar.vue";
import CopyFooter from "./components/CopyFooter.vue";

export default {
  name: "HomeView",
  components: {
    MenuBar,
    MenuPhoneBar,
    FooterBar,
    CopyFooter
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
@import "@/responsive";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu {
  @media (max-width: 1200px) {
    display: none;
  }

  @include responsive(mobile) {
    display: none;
  }
}

.phoneMenu {
  @media (min-width: 1200px) {
    display: none;
  }
}

.footer{
  position: relative;
}
</style>