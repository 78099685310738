<template>
  <v-footer class="footer">
    <div class="footer-logo">
      <v-img
        src="../assets/logo_goat-removebg-preview.png"
        max-width="150"
        max-height="150"
      ></v-img>
    </div>
    <div class="items">
      <div class="quick-links">
        <h2 style="color: #cd8502">Quick links</h2>
        <v-btn dark plain to="/" large> Home </v-btn>
        <v-btn dark plain to="/services" large> SERVICES </v-btn>
        <v-btn dark plain to="/gallery" large> GALLERY </v-btn>
        <v-btn dark plain to="/contact" large> CONTACT </v-btn>
      </div>
      <div class="schedule">
        <h2 style="color: #cd8502">Schedules</h2>
        <p style="color: white">Monday to Friday 8:00 am - 5:00 pm</p>
        <p style="color: white">Saturday 9:00 am - 2:00 pm</p>
        <p style="color: white">Sunday Closed</p>
      </div>
      <div class="footer-social-meida">
        <h2 style="color: #cd8502">Contact Us</h2>
        <a href="mailto:sales@goatrenovationsllc.com">
          <v-chip
            style="cursor: pointer"
            color="transparent"
            label
            text-color="#fff"
          >
            <v-icon left> mdi-email-outline </v-icon>
            <span class="font-weight-bold"> sales@goatrenovationsllc.com </span>
          </v-chip>
        </a>
        <div class="social-icons">
          <v-btn
            href="https://www.facebook.com/goatrenovationsllc"
            target="_blank"
            icon
          >
            <v-icon large color="#fff"> mdi-facebook </v-icon>
          </v-btn>
          <v-btn
            href="https://www.instagram.com/goatrenovationswa"
            target="_blank"
            icon
          >
            <v-icon large color="#fff"> mdi-instagram </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/responsive";
.footer {
  margin-top: 3rem;
  width: 100%;
  min-height: 15rem;
  background-color: #0c111b;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 2rem;

  @media (max-width: 1200px) {
    align-items: center;
    gap: 2rem;
  }

  @include responsive(mobile) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

.footer-logo {
  display: flex;
  justify-content: left;
  margin-right: 3rem;

  @include responsive(mobile) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-right: 0rem;
  }
}

.items {
  display: flex;
  gap: 5rem;
  align-items: start;

  @media (max-width: 1200px) {
    align-items: start;
    min-width: 255px;
    gap: 5rem;
  }

  @include responsive(mobile) {
    flex-direction: column;
    align-items: start;
    min-width: 255px;
    gap: 2rem;
  }
}

.quick-links {
  display: flex;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.footer-social-meida {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;

  @include responsive(mobile) {
    height: auto;
    min-width: 255px;
  }
}

.social-icons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}
</style>
