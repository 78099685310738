<template>
  <v-footer class="footer">
    <span style="color: white">
      &copy; All rights reserved, Goat Renovation & General Contractor,
      {{ currentYear }}.
    </span>
    <span class="powered">
      Powered by:
      <a
        style="color: white; text-decoration: none"
        href="https://jossueazzad.dev/"
        >JossueAzzad.dev</a
      >
    </span>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>


<style lang="scss" scoped>
@import "@/responsive";
.footer {
  width: 100%;
  background-color: #cd8502;
  display: flex;
  justify-content: center;
  align-items: center;


  @include responsive(mobile) {
    flex-direction: column;
    align-items: start;
  }
}

.powered {
  color: white;
  margin-left: 2rem;


  @include responsive(mobile) {
    margin-left: 0rem;
  }
}
</style>
