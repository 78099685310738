import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import GalleryView from "../views/GalleryView.vue";
import ContactView from "../views/ContactView.vue";
import ServicesView from "../views/ServicesView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "HomeView" },
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
    meta: { title: "GalleryView" },
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
    meta: { title: "ServicesView" },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: { title: "ContactView" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  if (window.gtag) {
    // Track page view
    window.gtag("config", "G-PQ3F3QWXQW", {
      page_path: to.fullPath,
    });
  }
  next();
});

export default router;
