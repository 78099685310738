var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('v-container',{staticClass:"services-container"},[(_vm.images.length == 0)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((4),function(index){return _c('v-col',{key:index,staticClass:"pa-5 mb-4",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"4","xl":"3"}},[(_vm.images.length == 0)?_c('v-sheet',{staticClass:"mx-auto",attrs:{"height":"100%","max-width":"400"}},[_c('v-skeleton-loader',{attrs:{"height":"280px","max-width":"480","type":"card"}})],1):_vm._e()],1)}),1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.images),function(image,index){return _c('v-col',{key:index,staticClass:"pa-5 mb-4",attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"4","xl":"3"}},[(
            image.active &&
            image.location &&
            image.description &&
            image.images[0]
          )?_c('v-card',{staticClass:"mx-auto",attrs:{"height":"30rem","max-width":"400"}},[_c('v-img',{staticClass:"image-card white--text align-end",attrs:{"height":"220px","width":"100%","src":image.images[0]}}),_c('v-card-subtitle',[(image.location)?_c('v-chip',{staticClass:"mb-3 font-weight-bold",attrs:{"color":"#0c111b","text-":"","pill":"","outlined":"","text-color":"#0c111b"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-map-marker ")]),_vm._v(" "+_vm._s(image.location)+" ")],1):_vm._e()],1),_c('v-card-text',{staticStyle:{"overflow":"scroll","height":"7rem"}},[_vm._v(" "+_vm._s(image.description)+" ")]),_c('v-card-actions',{staticStyle:{"justify-content":"flex-end"}},[_c('v-btn',{staticClass:"ma-2 galery-btn",staticStyle:{"color":"#cd8502"},attrs:{"outlined":"","tile":""},on:{"click":function($event){return _vm.zoomImage(index)}}},[_vm._v(" See Galery "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-image ")])],1)],1)],1):_vm._e()],1)}),1),_c('v-dialog',{attrs:{"overlay-opacity":"0.75","transition":"dialog-bottom-transition","max-width":"750"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-carousel',{staticClass:"carousel",attrs:{"hide-delimiters":"","dark":"","max-height":"80vh","height":"100%"}},_vm._l((_vm.zoomedUrl),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"item-carousel",attrs:{"eager":"","cover":""}},[_c('v-img',{attrs:{"max-width":"100%","max-height":"100%","src":item,"eager":""}})],1)}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-name ml-2"},[_c('h1',{attrs:{"ml-4":""}},[_vm._v("Our last "),_c('span',{staticStyle:{"color":"#cd8502"}},[_vm._v("Projects")])])])
}]

export { render, staticRenderFns }