import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDnyQsSB9AmL4yrVE5mBXNMPqqYlBEaFjE",
  authDomain: "goat-renovations.firebaseapp.com",
  projectId: "goat-renovations",
  storageBucket: "goat-renovations.appspot.com",
  messagingSenderId: "923606677476",
  appId: "1:923606677476:web:1be54155ddcea6987ab9f1",
  measurementId: "G-PQ3F3QWXQW",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
