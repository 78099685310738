<template>
  <div class="container">
    <div class="page-name ml-2">
      <h1 ml-4>Our last <span style="color: #cd8502">Projects</span></h1>
    </div>
    <v-container class="services-container">
      <v-row v-if="images.length == 0" no-gutters>
        <v-col
          v-for="index in 4"
          :key="index"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          class="pa-5 mb-4"
        >
          <v-sheet
            v-if="images.length == 0"
            class="mx-auto"
            height="100%"
            max-width="400"
          >
            <v-skeleton-loader
              height="280px"
              max-width="480"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          v-for="(image, index) in images"
          :key="index"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          class="pa-5 mb-4"
        >
          <v-card
            v-if="
              image.active &&
              image.location &&
              image.description &&
              image.images[0]
            "
            class="mx-auto"
            height="30rem"
            max-width="400"
          >
            <v-img
              class="image-card white--text align-end"
              height="220px"
              width="100%"
              :src="image.images[0]"
            >
            </v-img>
            <v-card-subtitle>
              <v-chip
                v-if="image.location"
                class="mb-3 font-weight-bold"
                color="#0c111b"
                text-
                pill
                outlined
                text-color="#0c111b"
              >
                <v-icon small left> mdi-map-marker </v-icon>
                {{ image.location }}
              </v-chip>
            </v-card-subtitle>

            <v-card-text style="overflow: scroll; height: 7rem">
              {{ image.description }}
            </v-card-text>

            <v-card-actions style="justify-content: flex-end">
              <v-btn
                outlined
                class="ma-2 galery-btn"
                style="color: #cd8502"
                @click="zoomImage(index)"
                tile
              >
                See Galery
                <v-icon class="ml-1"> mdi-image </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        overlay-opacity="0.75"
        transition="dialog-bottom-transition"
        max-width="750"
      >
        <v-carousel
          hide-delimiters
          dark
          class="carousel"
          v-if="dialog"
          max-height="80vh"
          height="100%"
        >
          <v-carousel-item
            class="item-carousel"
            v-for="(item, i) in zoomedUrl"
            :key="i"
            eager
            cover
          >
            <v-img max-width="100%" max-height="100%" :src="item" eager />
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { db } from "@/utils/firebase";
export default {
  data() {
    return {
      activeIndex: 1,
      images: [],
      dialog: false,
      zoomedUrl: [],
    };
  },
  methods: {
    zoomImage(index) {
      this.zoomedUrl = [];
      this.zoomedUrl = this.images[index].images;
      this.dialog = true;
    },

    async getGallery() {
      try {
        await db
          .collection("gallery")
          .where("active", "==", true)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((galleryItem) => {
              galleryItem = galleryItem.data();
              if (
                galleryItem.location &&
                galleryItem.description &&
                galleryItem.images[0]
              ) {
                this.images.push(galleryItem);
              }
            });
          });
        console.log(this.images);
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getGallery();
    this.images.length;
  },
};
</script>


<style>
.page-name h1 {
  @media (max-width: 1200px) {
    font-size: 25px;
  }
}

.item-carousel .v-image__image--cover {
  background-size: contain;
  background-color: #00000070;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  height: auto;
  max-height: 100vh;
}

.image-card {
  width: 380px;
  object-fit: contain;
}

.v-image__image--cover {
  background-size: cover;
  background-position: center center !important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.container {
  padding: 10px !important;
}

.galery-btn:hover {
  background: #d6a015;
  color: #ffffff !important;
}
</style>