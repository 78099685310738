<template>
  <div class="container">
    <div class="page-name">
      <h1 style="color: #0c111b">
        Our GOAT <span style="color: #cd8502">Services</span>
      </h1>
    </div>
    <v-container class="services-container">
      <v-row v-if="AllServices.length == 0" no-gutters>
        <v-col
          v-for="index in 6"
          :key="index"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          class="pa-5 mb-4"
        >
          <v-sheet
            v-if="AllServices.length == 0"
            class="mx-auto"
            height="100%"
            max-width="400"
          >
            <v-skeleton-loader
              height="280px"
              max-width="480"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          v-for="(service, index) in AllServices"
          :key="index"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          class="pa-5 mb-4"
        >
          <v-card class="mx-auto" height="100%" max-width="400">
            <v-img
              class="white--text align-end mx-auto"
              height="280px"
              max-width="480"
              :src="service.image"
            >
              <v-card-title
                style="
                  padding: 5px;
                  padding-left: 10px;
                  background-color: rgba(0, 0, 0, 0.5);
                "
              >
                {{ service.name }}</v-card-title
              >
            </v-img>

            <v-card-subtitle class="pb-0">
              <v-chip
                v-if="service.location"
                class="mb-3 font-weight-bold"
                color="grey lighten-2"
                text-
                pill
                text-color="grey darken-3"
              >
                <v-icon small left> mdi-map-marker </v-icon>
                {{ service.location }}
              </v-chip>
            </v-card-subtitle>
            <v-card-text class="pl-5 text--primary">
              <div>{{ service.description }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
    
    <script>
import { db } from "@/utils/firebase";
export default {
  data() {
    return {
      AllServices: [],
    };
  },
  methods: {
    async getServices() {
      try {
        await db
          .collection("services")
          .where("active", "==", true)
          .orderBy("position")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((service) => {
              service = service.data();
              console.log(service);
              this.AllServices.push(service);
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {
    console.log("all services", this.AllServices);
  },

  mounted() {
    this.getServices();
  },
};
</script>
    
    <style scoped>
</style>