<template>
  <div class="container">
    <div class="page-name">
      <h1>Contact<span style="color: #cd8502"> Us</span></h1>
      <div class="contact-container">
        <div class="text">
          <div class="message">
            <h2 style="font-size: 2.5rem; color: #fff">
              Get in <span style="color: #cd8502">touch.</span>
            </h2>
          </div>
          <div class="items">
            <div class="item">
              <v-icon class="mr-2" medium color="#cd8502">
                mdi-map-marker
              </v-icon>
              <span style="color: white">
                Renton, Washington, United States 98057
              </span>
            </div>
            <div class="item">
              <v-icon class="mr-2" medium color="#cd8502"> mdi-phone </v-icon>
              <span style="color: white"> +1 206-454-0769 </span>
            </div>
            <div class="item">
              <v-icon class="mr-2" medium color="#cd8502">
                mdi-email-open-outline
              </v-icon>
              <span style="color: white"> sales@goatrenovationsllc.com </span>
            </div>
            <div class="item">
              <v-icon class="mr-2" medium color="#cd8502"> mdi-web </v-icon>
              <span style="color: white"> goatrenovationsllc.com </span>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="message">
            <h2 style="font-size: 2.5rem">
              Quotes for <span style="color: #cd8502">free.</span>
            </h2>
          </div>
          <v-form class="pa-5" ref="form" v-model="valid" @submit.prevent>
            <v-container>
              <v-row>
                <v-col class="pa-3" cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="name"
                    filled
                    background-color="white"
                    color="#cd8502"
                    :rules="inputRules"
                    label="Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-3" cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="phoneNumber"
                    filled
                    background-color="white"
                    color="#cd8502"
                    :rules="inputRules"
                    label="Phone Number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-3" cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="email"
                    filled
                    background-color="white"
                    color="#cd8502"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-3" cols="12" md="6" sm="12">
                  <v-text-field
                    v-model="address"
                    filled
                    :rules="inputRules"
                    background-color="white"
                    color="#cd8502"
                    label="Address"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="pa-3" cols="12" md="12" sm="12">
                  <v-textarea
                    v-model="message"
                    class="text-fields-form"
                    filled
                    :rules="inputRules"
                    color="#cd8502"
                    background-color="white"
                    label="Message"
                    required
                  ></v-textarea>
                </v-col>
                <div class="btn-contariner">
                  <v-btn
                    color="#cd8502"
                    @click="sendEmail"
                    style="color: white"
                    class="pd-4"
                    type="submit"
                    :disabled="!valid"
                  >
                    Submit.
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </v-form>
          <v-snackbar top v-model="snackbar" :timeout="timeout">
            {{ snackText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
export default {
  name: "ContactView",
  components: {},

  data() {
    return {
      name: "",
      phoneNumber: "",
      email: "",
      address: "",
      message: "",
      valid: false,
      snackbar: false,
      snackText: "",
      timeout: 2000,
      base64Image: "/src/assets/base64Image.txt",

      idService: process.env.VUE_APP_SERVICE_ID,
      idTemplate: process.env.VUE_APP_TEMPLATE_CONTACT_ID,
      idUser: process.env.VUE_APP_USER_ID,

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      inputRules: [(v) => !!v || "Input is required"],
    };
  },

  methods: {
    async validate() {
      return this.$refs.form.validate();
    },

    clear() {
      this.$refs.form.reset();
    },

    async sendEmail() {
      let valid = await this.validate();
      if (valid) {
        const templateParams = {
          name: this.name,
          phoneNumber: this.phoneNumber,
          email: this.email,
          message: this.message,
          address: this.address,
        };

        emailjs
          .send(this.idService, this.idTemplate, templateParams, this.idUser)
          .then(() => {
            this.snackText = "Email Send!";
            this.snackbar = true;

            this.clear();
          })
          .catch((error) => {
            console.error("Error sending email:", error);
            this.snackText = "Error to send Email.";
            this.snackbar = true;
          });
      } else {
        this.snackText = "Error to send Email.";
        this.snackbar = true;
        this.clear();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/responsive";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-name h1 {
  @media (max-width: 1200px) {
    padding: 1rem;
  }

  @include responsive(mobile) {
    padding: 1rem;
  }
}
.contact-container {
  min-height: 60vh;
  width: 80vw;
  margin-top: 3rem;
  background-color: #ececec;
  display: flex;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100vw;
    padding: 2rem;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100vw;
  }
}

.text {
  background-image: url("/src/assets/patrondMadera.jpeg");
  flex: 2;
  padding: 4rem;
  width: 50%;
  @media (max-width: 1200px) {
    background-repeat: repeat-x;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
}

.form {
  flex: 3;
  width: 50%;
  padding: 3rem;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  margin-top: 3rem;
}

.item {
  font-size: 1.2rem;
  margin-top: 30px;
  overflow: hidden;
}

.form {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 100%;
  }

  @include responsive(mobile) {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 100%;
  }
}

.btn-contariner {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
}
</style>
